<template>
  <div id="app">
    <NuxtLoadingIndicator color="#00d6fe" />

    <header>
      <nav>
        <NuxtLink
          class="title"
          to="/"
        >
          HI3
        </NuxtLink>

        <div class="links">
          <NuxtLink to="/">
            Accueil
          </NuxtLink>
          <NuxtLink to="/chars">
            <span>Liste des </span>Personnages
          </NuxtLink>
          <NuxtLink to="/guides">
            Guides<span> et Liens</span>
          </NuxtLink>
        </div>
      </nav>
    </header>

    <div id="main">
      <NuxtPage />
    </div>

    <footer>
      <p>
        Ce site n'est pas officiel et n'est pas endorsé par miHoYo.<br>
        Les diverses images utilisés sur ce site sont la propriété de HoYoverse et miHoYo.<br>
        Nous ne réclamons aucun droits sur celles-ci et ne monétisons pas ce site.<br>
      </p>
      <p>
        Propulsé par Nuxt, hébergé chez Cloudflare.<br>
        Icône HoYoLab basé sur
        <a
          href="https://github.com/Donnnno/Arcticons"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >Arcticons</a>
        <small><em>&nbsp;(CC BY-SA 4.0)</em></small>.
      </p>
    </footer>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

const route = useRoute()

useHead({
  titleTemplate: (title) => (
    title ? `${title} | HI3` : 'HI3'
  ),
  title: null, // undefined is apparently not an option
  link: [
    { rel: 'canonical', href: `https://hi3.rita.moe${route.fullPath}` },
  ],
  meta: [
    { property: 'og:url', content: `https://hi3.rita.moe${route.fullPath}` },
  ],
  bodyAttrs: {
    class: () => `page-${route.name}`,
  },
})

onMounted(async () => {
  if (import.meta.client && 'serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js')
  }
})
</script>

<style lang="scss">
body {
  background-color: #1b1b23;
  background-image: url("/img/bg/news.jpg");
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  color: #efeff4;
  cursor: url("/img/cur/28.png") 2 4, auto;
  font-family: 'Microsoft YaHei', 微软雅黑, 'MicrosoftJhengHei', 华文细黑,
    STHeiti, MingLiu, 'Chinese Quote', 'PingFang SC', 'Hiragino Sans GB',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  overflow-y: scroll;
  word-wrap: break-word;

  @media screen and (min-width: 340px) {
    font-size: 13px;
  }

  @media screen and (min-width: 380px) {
    font-size: 15px;
  }

  &.page-launcher,
  &.page-chars {
    background-attachment: fixed;
    background-image: url("/img/bg/chars.avif");
    background-position-y: center;
    background-size: cover;
  }

  &.page-guides {
    background-attachment: fixed;
    background-image: url("/img/bg/media.jpg");
    background-position-y: center;
    background-size: cover;
  }
}

header {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;

  nav {
    align-items: center;
    display: flex;
    font-weight: bold;
    height: 65px;
    margin: 0 auto;
    max-width: 1280px;
    padding: 0 20px;
    width: calc(100% - 4em);

    .title {
      color: inherit;
      text-decoration: none;

      &:visited {
        color: inherit;
      }

      @media screen and (min-width: 380px) {
        font-size: 1.5rem;
      }
    }

    .links {
      a,
      a:visited {
        color: #747474;
        margin-left: 25px;
        text-decoration: none;

        &.router-link-active {
          border-bottom: 1px #ffa827 solid;
          color: #ffa827;
        }

        &:not(.router-link-active) {
          &:hover,
          &:focus {
            border-bottom: 2px rgba(255, 169, 39, 0.5) dashed;
          }
        }
      }
    }
  }
}

#main {
  margin: 0 auto;
  max-width: 1280px;
  min-height: calc(100vh - 8em);
  padding: 2rem;
  width: calc(100% - 4em);

  .title-container {
    // Easing from https://matthewlein.com/tools/ceaser
    animation: expand-width 1s cubic-bezier(0.230, 1.000, 0.320, 1.000); /* easeOutQuint */
    border-image-slice: 8;
    border-image-source: url('/img/border-underline.svg');
    border-left: none !important;
    border-style: solid;
    border-width: 9px;
    height: 1.85rem;
    margin: 0 1px 0.5rem;
    overflow-wrap: normal;
    overflow: visible;
    width: calc(100% - 10px);
    white-space: nowrap;

    h2 {
      animation: translate-in 1s cubic-bezier(0.230, 1.000, 0.320, 1.000); /* easeOutQuint */
      border-bottom: 1px solid #ffa827;
      display: inline-block;
      font-weight: 600;
      line-height: 1.25;
      margin: 0;
      padding-bottom: 7px;
    }
  }

  .link {
    cursor: pointer;
  }

  abbr,
  .help[title] {
    cursor: help;
    text-decoration: dotted underline #8b949e;
    text-underline-position: under;
  }

  .videoWrapper {
    height: 0;
    max-width: 100%;
    padding-bottom: 56.25%;
    position: relative;

    iframe,
    object,
    embed,
    video {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .content-body {
    background-color: rgba(23, 28, 39, 0.8);
    background-image: url('/img/grid.png');
    background-repeat: repeat;
    border-image-slice: 2;
    border-image-source: url('/img/border-content.svg');
    border-style: solid;
    border-width: 2px;
    box-shadow: 5px 4px rgba(0, 0, 0, 0.2);
    color: #f0f6fc;
    line-height: 1.5;
    overflow: hidden;
    padding: 15px;
    word-wrap: break-word;

    & > :first-child {
      margin-top: 0 !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      line-height: 1.25;

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    h4 {
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }

    h5 {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      margin-top: 1.5rem;

      &:before {
        content: '⮞ '
      }
    }

    small {
      font-size: 0.8rem;
    }

    a {
      background-color: transparent;
      color: #1f6feb;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    p,
    blockquote,
    ul,
    ol,
    dl,
    table,
    pre,
    details {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    ul,
    ol {
      padding-left: 2rem;

      li {
        margin-bottom: 0.4rem;
      }
    }

    hr {
      box-sizing: content-box;
      overflow: hidden;
      background: transparent;
      border-bottom: 1px solid rgba(61, 68, 77, 0.7);
      height: 0.25rem;
      padding: 0;
      margin: 1.5em 0;
      background-color: #3d444d;
      border: 0;
    }

    img,
    video {
      background: transparent;
      border-image-slice: 3;
      border-image-source: url('/img/border-media.svg');
      border-style: solid;
      border-width: 2px;
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 480px;
      padding: 4px;
      width: calc(100% - 12px); // remove (padding + border) * 2

      &[onclick] {
        cursor: pointer;
      }

      &.notfound {
        float: right;
        margin-left: 1rem;
        max-height: 25vh;
        width: auto;
      }
    }

    table {
      border-collapse: collapse;
      border-spacing: 0;
      display: block;
      font-variant: tabular-nums;
      max-width: 100%;
      overflow: auto;
      width: -moz-max-content;
      width: max-content;

      tr {
        background-color: #1a1a1a;
        border-top: 1px solid rgba(61, 68, 77, 0.7);

        &.odd {
          background-color: #141414;
        }

        &.empty {
          background-color: transparent;

          td {
            border: none;
          }
        }
      }

      th,
      td {
        border: 1px solid #3d444d;
        padding: 6px 13px;
      }
    }

    spoilers {
      background: black;
      color: black;
      text-decoration: none;

      &:hover,
      &:active {
        color: white;
      }
    }
  }
}

.simple-icons {
  align-self: center;
  fill: currentColor;
  vertical-align: -0.175rem;
}

footer {
  background: #000;
  font-size: 12px;
  padding: 30px 20px;
  text-align: center;

  a {
    color: inherit !important;
  }
}

@media screen and (max-width: 950px) {
  header nav {
    .links {
      span {
        display: none;
      }
    }
  }

  #main {
    margin: 0;
    padding: 1em .5rem;
    width: calc(100% - 1em);

    .content-body img.notfound {
      display: block;
      float: none;
      margin: 0 auto;
    }
  }
}

@keyframes expand-width {
  0% { width: 0px; opacity: 0; }
  40% { width: 0px; opacity: 0; }
  50% { width: 0px; opacity: 1; }
  60% { width: 0px; opacity: 0; }
  70% { width: 0px; opacity: 1; }
  100% { width: calc(100% - 10px); }
}

@keyframes translate-in {
  0% { transform: translateX(25px); opacity: 0; }
  70% { transform: translateX(25px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}
</style>
