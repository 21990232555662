<template>
  <div
    id="version-pc"
    class="content-body"
  >
    <h4>Version PC</h4>

    <p>
      Depuis le 26 décembre 2019, les capitaines US/EU (Global) et KR peuvent jouer à Honkai Impact 3rd sans utiliser d'émulateur sur PC, à l'occasion de la 3.6.<br>
      Le serveur CN a eu une Open Beta depuis le 1er août 2019.<br>
      La version SEA et TW ont eux aussi enfin accès à la version PC depuis le 13 février 2020.<br>
      Le Japon a obtenu un accès natif sur PC avec la version Google Play et Steam en mi/fin-2021.<br>
      Une version pour les Mac équipés d'un processeur Apple "M" est disponible depuis la 7.4 et peut être téléchargée sur l'App Store.
    </p>

    <p>
      <em><strong>Attention,</strong></em> bien qu'<a
        href="https://store.steampowered.com/app/1671200/Honkai_Impact_3rd/"
        target="_blank"
        rel="noopener"
      >aussi sur Steam</a>
      et avec du cross-play... on ne peut <strong>pas</strong> lier un compte HoYoverse !<br>
      Un nouvelle partie va donc être chargée et liée à votre compte Steam. Vous ne pouvez (pour l'instant ?) pas ré-utiliser cette sauvegarde sur une autre plateforme.
    </p>

    <p>
      Alternativement, vous pouvez aussi obtenir le jeu depuis l'<a
        href="https://store.epicgames.com/fr/p/honkai-impact-3rd"
        target="_blank"
        rel="noopener"
      >Epic Games Store</a>
      ou le
      <a
        href="https://play.google.com/store/apps/details?id=com.miHoYo.bh3global"
        target="_blank"
        rel="noopener"
      >Play Store</a>.<br>
      Il télécharge un launcher légèrement modifié mais demande un compte HoYoverse, permettant le cross-save !<br>
      Celui du Play Store vous permet de faire les paiements avec votre compte Google, apportant la sauvegarde de votre moyen de paiement,
      l'utilisation de cartes cadeau, crédits et bons, ainsi que le gain des Play Points.
    </p>

    <hr>

    <p>
      <strong>
        Si vous rencontrez des problèmes,
        <a
          href="https://web.archive.org/web/20230318052754/https://honkaiimpact3.hoyoverse.com/global/fr-fr/news/2120"
          target="_blank"
          rel="noopener"
        >consultez cette FAQ</a>.
      </strong>
      <br>
      <small>
        <em>(Note : la page originale a été supprimée (trop ancienne ?), une archive est donc fournie.)</em>
      </small>
    </p>

    <p>
      Quelques infos extraites de la FAQ :
    </p>

    <ul>
      <li>
        Liez votre compte HoYoverse pour partager votre progression entre les plateformes <small>(sauf Steam)</small>.
      </li>
      <li>
        Uniquement officiellement supporté pour Windows en 64-bit.
      </li>
      <li>
        Le jeu supporte les manettes "grand public". <small>(Testé nativement avec les manette Xbox et PS4/PS5.)</small>
      </li>
    </ul>

    <hr>

    <p>
      Matériel recommandé dès la 7.3 (ou meilleur, équivalent AMD de notre part) :
    </p>

    <ul>
      <li>
        Processeur : Intel Core i5 de 8ème gén. (i5-8xxx) ou AMD Ryzen 5 5ème gén. (55xx)
      </li>
      <li>
        Carte graphique : GTX 1060 ou Radeon RX 480, avec au moins 6 Go de vRAM et le support de DirectX 11
      </li>
      <li>
        Mémoire vive (RAM) : 16 Go
      </li>
      <li>
        Espace disque : Plus de 60 Go sur un SSD
      </li>
      <li>
        Système : Windows 10
      </li>
    </ul>

    <p>
      Configuration minimale : Intel Core i5 de 6ème gén., 8 Go de RAM, GTX 1030 ou Intel UHD630, Windows 7.
    </p>

    <hr>

    <p>
      <a href="/launcher">Historique du launcher</a><br>
      <a href="/launcher">
        <img
          alt="Le launcher HoYoPlay en 8.0"
          src="/img/launchers/8.0-launcher.png"
          title="Le launcher HoYoPlay en 8.0"
        >
      </a>
    </p>
  </div>
</template>
