
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93eJNUfhAGv0Meta } from "/workspace/kody/hi3/app/pages/[...slug].vue?macro=true";
import { default as charspqZm0Z63RLMeta } from "/workspace/kody/hi3/app/pages/chars.vue?macro=true";
import { default as guidesTEegi1ZJiRMeta } from "/workspace/kody/hi3/app/pages/guides.vue?macro=true";
import { default as indexm02Bp2mt3jMeta } from "/workspace/kody/hi3/app/pages/index.vue?macro=true";
import { default as launcheryAqxnum6lTMeta } from "/workspace/kody/hi3/app/pages/launcher.vue?macro=true";
import { default as component_45stubxcTLMc6AMMMeta } from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubxcTLMc6AMM } from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/workspace/kody/hi3/app/pages/[...slug].vue")
  },
  {
    name: "chars",
    path: "/chars",
    component: () => import("/workspace/kody/hi3/app/pages/chars.vue")
  },
  {
    name: "guides",
    path: "/guides",
    component: () => import("/workspace/kody/hi3/app/pages/guides.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/kody/hi3/app/pages/index.vue")
  },
  {
    name: "launcher",
    path: "/launcher",
    component: () => import("/workspace/kody/hi3/app/pages/launcher.vue")
  },
  {
    name: component_45stubxcTLMc6AMMMeta?.name,
    path: "/valks",
    component: component_45stubxcTLMc6AMM
  }
]