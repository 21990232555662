<template>
  <div
    id="collapse-launcher"
    class="content-body"
  >
    <h4>Collapse Launcher</h4>

    <p>
      Ce launcher est une bonne alternative pour tous les jeux HoYoverse sur PC.<br>
      Bien que non-officiel, il ne casse pas les règles du "Fair Gaming" vu qu'il n'applique aucune modification.
    </p>

    <p>
      Quelques avantages :
    </p>

    <ul>
      <li>Une interface moderne et optimisée.</li>
      <li>Accès rapide à tous les jeux et toutes les régions.</li>
      <li>Téléchargements en parallèle et multi-processeur.</li>
      <li>Vous pouvez utiliser une image de fond personalisée.</li>
      <li>Certains réglages du jeu peuvent être changés depuis le launcher.</li>
      <li>Il peut précharger les caches ("settings") et les nouvelles versions.</li>
      <li>Un outil vous permet de réparer certains fichiers qui ne sont pas installés correctement.</li>
      <li>Un navigateur est intégré pour rapidement accéder aux annonces, le HoYoLab, et divers outils.</li>
    </ul>

    <p>
      Pour le télécharger, rendez-vous sur
      <a
        href="https://github.com/CollapseLauncher/Collapse/blob/main/README.md#download-ready-to-use-builds"
        target="_blank"
        rel="noopener"
      >la page du projet</a>
      et cliquez sur le bouton jaune "Download stable build".
    </p>

    <a
      href="/img/news/collapse-1.82.13.0-full.mp4"
      target="_blank"
      title="Démonstration de Collapse (cliquez pour agrandir)"
    >
      <video
        autoplay
        loop
        muted
        src="/img/news/collapse-1.82.13.0.mp4"
      />
    </a>
  </div>
</template>
