<template>
  <div class="page-home">
    <div class="news">
      <div class="title-container">
        <h2>Informations</h2>
      </div>

      <VersionPC />

      <CollapseLauncher />

      <VersionMobile />
    </div>

    <div class="side">
      <div class="note">
        <span>⚠</span>
        <strong>Ce site n'est pas officiel.</strong><br>
        Il regroupe des ressources pour
        <a
          href="https://honkaiimpact3.hoyoverse.com/global/fr-fr/home"
          rel="nofollow noopener noreferrer"
          target="_blank"
          title="Visiter le site officiel du jeu"
        >Honkai Impact 3rd</a>.
      </div>

      <Status />
      <Download />
      <Social />
    </div>
  </div>
</template>

<script setup>
const title = 'HI3'
const description = 'Quelques infos sur Honkai Impact 3rd en français.'
useSeoMeta({
  title: null,
  ogTitle: title,
  description: description,
  ogDescription: description,
})
</script>

<style lang="scss">
.page-home {
  display: flex;

  .news {
    flex-grow: 1;

    .content-body {
      margin-bottom: 1em;
    }
  }

  .side {
    margin-left: 2em;
    min-width: 370px;
    width: 370px;

    h3 {
      margin: 0 0 5px;
    }

    .note {
      background-color: rgba(173, 0, 0, 0.3);
      background-image: url('/img/grid.png');
      background-repeat: repeat;
      border-image-slice: 2;
      border-image-source: url('/img/border-alert.svg');
      border-style: solid;
      border-width: 2px;
      font-size: .85em;
      margin-bottom: 16px;
      margin-top: -20px;
      padding: 5px 10px;
      padding: 5px 10px;

      span {
        margin-right: 8px;
        vertical-align: 1px;
      }

      a {
        color: inherit;

        &:visited,
        &:hover {
          color: inherit;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .page-home {
    flex-direction: column;

    .side {
      margin-left: 0;
      min-width: unset;
      order: 1;
      text-align: center;
      width: 100%;

      .note {
        margin-top: 0;
      }

      .status {
        margin: 0 0 1em;
      }

      .download {
        display: inline-block;
        margin: 0 1em 1em;
      }

      .social {
        display: inline-block;
        margin: 0 1em 1em;
        vertical-align: top;
      }
    }

    .news {
      order: 2;
    }
  }
}
</style>
