<template>
  <div
    id="version-mobile"
    class="content-body"
  >
    <h4>
      Version Mobile
    </h4>

    <p>
      Veuillez noter que la version globale s'appelle exactement "Honkai Impact 3rd".<br>
      Si vous trouvez "Honkai Impact 3" (sans le "rd"), il s'agit de la version SEA !
    </p>

    <hr>

    <h5 id="ios">
      iOS / iPadOS
    </h5>

    <p>
      Il est demandé d'utiliser au minimum iOS 12 et d'avoir plus 25 Go de stockage.<br>
      <em>Les manettes sont supportés dès iOS 14.</em>
    </p>

    <p>
      Les modèles recommandés (ou plus récent) :
    </p>

    <ul>
      <li>
        iPhone X/XR (2017)
      </li>
      <li>
        iPhone SE 2nd gén. (2020)
      </li>
      <li>
        iPad 8ème gén. (2020)
      </li>
      <li>
        iPad Mini 5ème gén. (2019)
      </li>
      <li>
        iPad Air 3ème gén. (2019)
      </li>
      <li>
        iPad Pro 12.9" 3ème gén. / 11" 1ère gén. (2018)
      </li>
    </ul>

    <p>
      La configuration minimale concerne ces modèles :
    </p>

    <ul>
      <li>
        iPhone 8 Plus (2017)
      </li>
      <li>
        iPad 6ème gen. (2018)
      </li>
      <li>
        iPad Mini 5ème gén. (2019)
      </li>
      <li>
        iPad Air 3ème gén. (2019)
      </li>
      <li>
        iPad Pro 2ème gén. (2017)
      </li>
    </ul>

    <hr>

    <h5 id="android">
      Android
    </h5>

    <p>
      Les spécifications recommandées sont :
    </p>

    <ul>
      <li>Android 11 ou plus récent</li>
      <li>6 Go de RAM ou plus</li>
      <li>25 Go de stockage</li>
      <li>
        Processeur (ou plus récent) <small>et modèles minimum</small> :
        <ul>
          <li>
            Snapdragon 855 (2019) - <small><em>Samsung Galaxy S10/Note 10/A90/W20/Fold/Flip, Google Pixel 4, OnePlus 7, Xiaomi Mi 9, Redmi K20, Asus Zenfone 6, Sony Xperia 5, ZTE Axon 10 Pro, Lenovo Z6 Pro, ...</em></small>
          </li>
          <li>
            Kirin 990 (2019) - <small><em>Huawei P40, Huawei Mate 30, Huawei Honor 30, ...</em></small>
          </li>
          <li>
            Dimensity 1000 (2020) - <small><em>Huawei Honor V40, Oppo Reno3, Redmi K30 Ultra, realme X7 Pro, vivo IQOO Z1, ...</em></small>
          </li>
          <li>
            Exynos 9820 (2019) - <small><em>Samsung Galaxy S10</em></small>
          </li>
        </ul>
      </li>
    </ul>

    <p>
      La configuration minimale :
    </p>

    <ul>
      <li>Android 8</li>
      <li>4 Go de RAM</li>
      <li>25 Go de stockage</li>
      <li>
        Processeur <small>et modèles minimum</small> :
        <ul>
          <li>
            Snapdragon 835 (2017) - <small><em>Samsung Galaxy S8/Note 8, Google Pixel 2, OnePlus 5, Xiaomi Mi6, Xperia XZ1, ...</em></small>
          </li>
          <li>
            Kirin 810 (2019) - <small><em>Huawei P40 Lite, Huawei Honor 9X Pro, Huawei nova 5, ...</em></small>
          </li>
          <li>
            Dimensity 720 (2020) - <small><em>Samsung Galaxy A32 5G/M32 5G, realme V3, Huawei Nova 8 SE, ...</em></small>
          </li>
          <li>
            Exynos 980 (2019) - <small><em>Samsung Galaxy A51 5G/A71 5G, vivo S6/X30, ...</em></small>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
