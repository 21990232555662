import revive_payload_client_A0APcn9l40 from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fXsjnGrJtI from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4UURN58qgf from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_MJzOIED8nR from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bgKvWYgR3h from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bsMKfAAMEF from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_7RUBtm5fYP from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_DPjz8qZYEY from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/workspace/kody/hi3/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_9EwuHSU51E from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_matzaerzjjg5i5jropyc35xzqi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_A0APcn9l40,
  unhead_fXsjnGrJtI,
  router_4UURN58qgf,
  payload_client_MJzOIED8nR,
  navigation_repaint_client_bgKvWYgR3h,
  check_outdated_build_client_bsMKfAAMEF,
  view_transitions_client_7RUBtm5fYP,
  chunk_reload_client_DPjz8qZYEY,
  components_plugin_zlvi6dcIsi,
  prefetch_client_9EwuHSU51E
]