import revive_payload_client_5KUJxCdpVu from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QvvMHPqPcP from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GwSDdFBBsE from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_M0FNx5avBR from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LQ1ZKfKUcd from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_91rLKDP3YI from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_Pb5JjwGP5X from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_8ua2IsRTkj from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/workspace/kody/hi3/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_bZxnNKBaYs from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.9_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_dtgdytx3xrbf26z5u4m74ifsea/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_5KUJxCdpVu,
  unhead_QvvMHPqPcP,
  router_GwSDdFBBsE,
  payload_client_M0FNx5avBR,
  navigation_repaint_client_LQ1ZKfKUcd,
  check_outdated_build_client_91rLKDP3YI,
  view_transitions_client_Pb5JjwGP5X,
  chunk_reload_client_8ua2IsRTkj,
  components_plugin_zlvi6dcIsi,
  prefetch_client_bZxnNKBaYs
]